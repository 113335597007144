import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const AllVideos = ({ pageContext, includeLayout = true, includeNav = true, includeBreadcrumb = true }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: '0z3x7Dm9wWc',
			url: 'https://www.youtube.com/embed/0z3x7Dm9wWc?si=wKzE2VwB-StBgNlU',
			title: 'How to Add a Retention Wire on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Follow these easy steps to learn how to add or replace the retention wire on the Horizon Go AX hearing aids by hear.com.'
		},
		{
			id: '2kJGkiyh3BI',
			url: 'https://www.youtube.com/embed/2kJGkiyh3BI?si=wKCYHzJtFyayvG-8',
			title: 'How to Change Domes on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Follow these easy steps to learn how to change the silicone domes on the Horizon Go AX hearing aids by hear.com.'
		},
		{
			id: 'Piq2S_5hVLc',
			url: 'https://www.youtube.com/embed/Piq2S_5hVLc?si=b0dLdaKkwWIdCMy5',
			title: 'How to Change Receivers on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Learn how to change the receivers on the Horizon Go AX hearing aids by hear.com by following these easy steps.'
		},
		{
			id: '2Lvzfwjr73E',
			url: 'https://www.youtube.com/embed/2Lvzfwjr73E?si=QpchIT94SLaukJSz',
			title: 'How to Change Wax Guards on Horizon Go AX Hearing Aids | hear.com',
			subtitle: 'Change your Horizon Go AX hearing aids wax guards with these easy steps.'
		},
		{
			id: 'EbkFgU0igyk',
			url: 'https://www.youtube.com/embed/EbkFgU0igyk?si=yUUutvJ3scMZAly5',
			title: 'How to clean Horizon Hearing aids | hear.com',
			subtitle: 'Watch this video to learn how to properly clean your Horizon hearing aids by hear.com.'
		},
		{
			id: '70imervmS4c',
			url: 'https://www.youtube.com/embed/70imervmS4c?si=n07n_tuaerPG33It',
			title: 'How to turn Pure Charge&Go AX on and off',
			subtitle: 'Follow these steps to learn how to turn your Pure Charge&Go AX RIC hearing aids on and off.'
		},
		{
			id: 'EeKoIrOKxvA',
			url: 'https://www.youtube.com/embed/EeKoIrOKxvA?si=CQjunhIg5GdOAOkU',
			title: 'How to insert Pure Charge&Go AX in the ear',
			subtitle: 'Learn how to insert a Pure Charge&Go AX Receiver-in-canal (RIC) hearing aid in your ear.'
		},
		{
			id: 'Y_-2HOv3M90',
			url: 'https://www.youtube.com/embed/Y_-2HOv3M90?si=RmSMDxPQPVbgp1IE',
			title: 'How to charge Pure Charge&Go AX with the Pure Portable Charger',
			subtitle: 'Learn how to charge the Signia Pure Charge&Go AX receiver-in-canal hearing aids.'
		},
		{
			id: '92rCUU0UY9c',
			url: 'https://www.youtube.com/embed/92rCUU0UY9c?si=tgC6OVvb8f6pSCqi',
			title: 'How to pair your hear.com hearing aids with Android | hear.com',
			subtitle:
				'Learn how to connect your hear.com Bluetooth hearing aids to your Android phone with this easy-to-follow tutorial.'
		},
		{
			id: 'NhN40ZBarpU',
			url: 'https://www.youtube.com/embed/NhN40ZBarpU?si=SQjp-dW6Lb-b4Gnb',
			title: 'How to connect hear.com hearing aids to iPhone | hear.com',
			subtitle:
				'Learn how to connect your hear.com Bluetooth hearing aids to your iPhone with this easy-to-follow tutorial.'
		},
		{
			id: 'bWLXnGkSubI',
			url: 'https://www.youtube.com/embed/bWLXnGkSubI?si=5PpLYV1BmV9ECO7v',
			title: 'How to read an Audiogram | hear.com',
			subtitle: 'Watch this video to easily learn how to read and understand your audiogram results.'
		},
		{
			id: '0z3x7Dm9wWc',
			url: 'https://www.youtube.com/embed/0z3x7Dm9wWc?si=wKzE2VwB-StBgNlU',
			title: 'How to Add a Retention Wire on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Follow these easy steps to learn how to add or replace the retention wire on the Horizon Go AX hearing aids by hear.com.'
		},
		{
			id: '2kJGkiyh3BI',
			url: 'https://www.youtube.com/embed/2kJGkiyh3BI?si=wKCYHzJtFyayvG-8',
			title: 'How to Change Domes on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Follow these easy steps to learn how to change the silicone domes on the Horizon Go AX hearing aids by hear.com.'
		},
		{
			id: 'Piq2S_5hVLc',
			url: 'https://www.youtube.com/embed/Piq2S_5hVLc?si=b0dLdaKkwWIdCMy5',
			title: 'How to Change Receivers on Horizon Go AX Hearing Aids | hear.com',
			subtitle:
				'Learn how to change the receivers on the Horizon Go AX hearing aids by hear.com by following these easy steps.'
		},
		{
			id: '2Lvzfwjr73E',
			url: 'https://www.youtube.com/embed/2Lvzfwjr73E?si=QpchIT94SLaukJSz',
			title: 'How to Change Wax Guards on Horizon Go AX Hearing Aids | hear.com',
			subtitle: 'Change your Horizon Go AX hearing aids wax guards with these easy steps.'
		},
		{
			id: 'mBiyjkcXLgg',
			url: 'https://www.youtube.com/embed/mBiyjkcXLgg?si=L9tY14BfgeaddOa2',
			title: 'How to use hear.com HORIZON App for Horizon Hearing Aids | hear.com',
			subtitle:
				'Follow this step-by-step tutorial guide to maximize the potential of the hear.com HORIZON App for Horizon Hearing Aids by hear.com'
		},
		{
			id: 'mGah42Robj4',
			url: 'https://www.youtube.com/embed/mGah42Robj4?si=aF-OqiY6kpfRa9N5',
			title: 'How to insert batteries on Sony CRE-C10 OTC Hearing Aids | hear.com',
			subtitle: 'Learn how to insert batteries on Sony CRE-C10 Over-the-counter hearing aids.'
		},
		{
			id: 'wSremDbrvLI',
			url: 'https://www.youtube.com/embed/wSremDbrvLI?si=KPgC49Npkg7XiFj1',
			title: 'How to change the click sleeves and clean the Sony CRE-C10 OTC Hearing Aids | hear.com',
			subtitle: 'Learn to change click sleeves and clean your CRE-C10 Self-fitting OTC Hearing Aids with ease.'
		},
		{
			id: 'S2O5sXrT2ro',
			url: 'https://www.youtube.com/embed/S2O5sXrT2ro?si=NK4L5QomxOU2lFCO',
			title: 'How to pair Sony CRE-C10 OTC Hearing Aids with Sony Hearing Control App | hear.com',
			subtitle:
				'Learn how to pair the Sony CRE-C10 OTC Hearing Aids to the Sony Hearing Control App for iOS™ and Android™'
		},
		{
			id: 'koNlHJjbtZY',
			url: 'https://www.youtube.com/embed/koNlHJjbtZY?si=_0xwQcuVGQr4RH2F',
			title: 'How to Insert , fit , and remove Sony CRE-C10 OTC Hearing Aids | hear.com',
			subtitle: 'Follow these easy steps to learn how to insert, fit and remove the Sony CRE-C10 hearing aids.'
		},
		{
			id: 'DugH-kel2yo',
			url: 'https://www.youtube.com/embed/DugH-kel2yo?si=A2KVVB-IGhXDO3Ah',
			title: 'Introducing the Sony Self-Fitting OTC Hearing Aids | hear.com',
			subtitle:
				'Meet the comfortable, easy-to-use OTC solution from Sony, helping you hear what matters most.'
		},
		{
			id: 'U_q8wgzWzT4',
			url: 'https://www.youtube.com/embed/U_q8wgzWzT4?si=KKEShVONuKe8YWTN',
			title: 'How to change the click sleeves and clean your CRE-E10 OTC Hearing Aids | hear.com',
			subtitle:
				'Walk through the steps to change and clean the soft ear tips of the Sony CRE-E10 OTC hearing aids.'
		},
		{
			id: '_zhViQW8nLE',
			url: 'https://www.youtube.com/embed/_zhViQW8nLE?si=G51TW5y8sKuSGHeX',
			title: 'How to insert, fit, and remove the Sony CRE-E10 OTC Hearing Aids | hear.com',
			subtitle:
				'This tutorial walks you through the steps to insert, fit, and remove the Sony CRE-E10 hearing aids.'
		},
		{
			id: 'hksjjVLUByA',
			url: 'https://www.youtube.com/embed/hksjjVLUByA?si=Zs0Ca_2ESUlX0yfa',
			title: 'How to turn the your CRE-E10 OTC Hearing Aids on and off | hear.com',
			subtitle:
				'Learn how to turn on and off your Sony CRE-E10 OTC hearing aids by following the steps shown in this video.'
		},
		{
			id: 'bSuU2iHTy7I',
			url: 'https://www.youtube.com/embed/bSuU2iHTy7I?si=5oUy-KL6wiTUEgww',
			title: 'Self-fitting Sony CRE-E10 Hearing Aids with iPhone | hear.com',
			subtitle:
				'Experience effortless fitting with Sony CRE-E10 hearing aids with iPhone for personalized comfort and superior sound.'
		},
		{
			id: 'HZ98iivm5zs',
			url: 'https://www.youtube.com/embed/HZ98iivm5zs?si=rFe4Po8iOgiuUMd4',
			title: 'How to connect Sony CRE-E10 Hearing Aids to iPhone Bluetooth | hear.com',
			subtitle: 'Connect your Sony CRE-E10 hearing aids to iPhone Bluetooth for instant, clear audio.'
		},
		{
			id: 'r5OMF39jjWU',
			url: 'https://www.youtube.com/embed/r5OMF39jjWU?si=K9O_9Z5MDsY2Uj96',
			title: 'Create Sony account for hearing aids with iPhone | hear.com',
			subtitle: 'Create a Sony account on your iPhone for easy management of your hearing aids.'
		},
		{
			id: '61uonLZOMyg',
			url: 'https://www.youtube.com/embed/61uonLZOMyg?si=1sC4UEH4YCThITgC',
			title: 'Download Sony Hearing Control App on iPhone | hear.com',
			subtitle: 'Quickly download the Sony Hearing Control App on your iPhone for seamless hearing aid management.'
		},
		{
			id: 'q2s19Mm23LA',
			url: 'https://www.youtube.com/embed/q2s19Mm23LA?si=xRnb3o2OriKox8aT',
			title: 'Self-fitting Sony CRE-E10 Hearing Aids with Android | hear.com',
			subtitle:
				'Experience effortless fitting with Sony CRE-E10 hearing aids with Android for personalized comfort and superior sound.'
		},
		{
			id: 'W94stD2iBYA',
			url: 'https://www.youtube.com/embed/W94stD2iBYA?si=6f0qXC7aJ1FWM3sx',
			title: 'How to connect Sony CRE-E10 Hearing Aids to Android Bluetooth | hear.com',
			subtitle: 'Connect your Sony CRE-E10 hearing aids to Android Bluetooth for instant, clear audio.'
		},
		{
			id: 'AegqFp0jaV0',
			url: 'https://www.youtube.com/embed/AegqFp0jaV0?si=aTAyVEGvCcrb9AhP',
			title: 'Create Sony account for hearing aids with Android | hear.com',
			subtitle: 'Create a Sony account on your Android for easy management of your hearing aids.'
		},
		{
			id: '-2iqZJSB-IA',
			url: 'https://www.youtube.com/embed/-2iqZJSB-IA?si=rOY3vrswP5TvP236',
			title: 'Download Sony Hearing Control App on Android | hear.com',
			subtitle: 'Quickly download the Sony Hearing Control App on your Android for seamless hearing aid management.'
		},
		{
			id: 'eihPzvlUn_s',
			url: 'https://www.youtube.com/embed/eihPzvlUn_s?si=8d7GCx3dWXaKjCKG',
			title: 'Stories and Testimonials from hear.com Customers | hear.com',
			subtitle:
				'Discover the heartwarming stories and testimonials of satisfied hear.com customers in this captivating video'
		},
		{
			id: 'LSysf7_xogE',
			url: 'https://www.youtube.com/embed/LSysf7_xogE?si=lTYVEI9qrXMC_VfZ',
			title: 'Meet Whitney - Customer Testimonial | hear.com',
			subtitle: 'Get to know Whitneys experience of the process to better hearing with hear.com'
		},
		{
			id: 'hjo1Iy3l2Qw',
			url: 'https://www.youtube.com/embed/hjo1Iy3l2Qw?si=yPzjjaoybPNM_LiH',
			title: 'Meet Scott - Customer Testimonial | hear.com',
			subtitle: 'Listen to Scotts journey to realizing his need of hearing aids and how they changed his life.'
		},
		{
			id: 'MSBvgD1Sxk4',
			url: 'https://www.youtube.com/embed/MSBvgD1Sxk4?si=WaVYzKkxcnM6umhe',
			title: 'Meet Martin - Customer Testimonial | hear.com',
			subtitle:
				'Martin renewed his sense of wonder and appreciation for the richness of life after getting hear.com hearing aids.'
		},
		{
			id: 'WsXI2a8eh4I',
			url: 'https://www.youtube.com/embed/WsXI2a8eh4I?si=LPmy5dWFnBrkA2Xz',
			title: 'Meet Michael - Customer Testimonial | hear.com',
			subtitle: 'After getting his hearing aids, he has rediscovered the joy of engaging in meaningful conversations.'
		},
		{
			id: 'gy-9swZ4iH4',
			url: 'https://www.youtube.com/embed/gy-9swZ4iH4?si=ZeVOHdw_PF61PzXA',
			title: 'Meet Bill - Customer Testimonial | hear.com',
			subtitle: 'Bill joyfully recounts how he regained his life when he started wearing hearing aids'
		},
		{
			id: 'fp0FEsdyGRY',
			url: 'https://www.youtube.com/embed/fp0FEsdyGRY?si=dw5f7n64f7NnJtCb',
			title: 'Meet Jimmy - Customer Testimonial | hear.com',
			subtitle:
				'Jimmy passionately shares that every day you postpone addressing your hearing loss, you are missing out on valuable experiences'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	const content = (
		<>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				{includeBreadcrumb && (
					<div className="o-row-2p5">
						<CustomBreadcrumb location={location} crumbs={crumbs} />
					</div>
				)}
				{includeNav && <VideoCategoryNav />}
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</>
	);

	return includeLayout ? <Layout>{content}</Layout> : content;
};

export default AllVideos;
