import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

const categories = [
	{ name: 'All', path: '/resources/videos/all/' },
	{ name: 'Horizon BTE', path: '/resources/videos/horizon-bte/' },
	{ name: 'Signia BTE', path: '/resources/videos/signia-bte/' },
	{ name: 'How-To Videos', path: '/resources/videos/how-to-videos/' },
	{ name: 'Sony CRE-C10', path: '/resources/videos/sony-cre-c10/' },
	{ name: 'Sony CRE-E10', path: '/resources/videos/sony-cre-e10/' },
	{ name: 'Testimonials', path: '/resources/videos/testimonials/' }
];

const normalizePath = (path) => path.replace(/\/$/, '').replace(/^\/\d+\//, '/'); // Adjust for numeric prefix

const VideoCategoryNav = () => {
	const location = useLocation();
	const [selectedCategory, setSelectedCategory] = useState('');

	useEffect(() => {
		const normalizedPath = normalizePath(location.pathname);
		console.log('Current Pathname:', location.pathname);
		console.log('Normalized Path:', normalizedPath);
		const currentCategory = categories.find((category) => normalizePath(category.path) === normalizedPath);
		console.log('Found Category:', currentCategory);
		if (currentCategory) {
			setSelectedCategory(currentCategory.name);
		} else {
			setSelectedCategory('');
		}
	}, [location.pathname]);

	return (
		<div className="o-row-3p5">
			<div className="c-video-category__scroll-container">
				{categories.map((category) => (
					<Link
						key={category.name}
						to={category.path}
						className={`c-video-category__btn ${selectedCategory === category.name ? 'selected' : ''}`}
					>
						{category.name}
					</Link>
				))}
			</div>
		</div>
	);
};

export default VideoCategoryNav;
